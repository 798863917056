import React from 'react'
import { CSSProperties } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as styles from './TopPage.module.scss'

const topImagePropeties: React.CSSProperties = {
  position: 'absolute',
}

const titleItemPropeties: React.CSSProperties = {
  position: 'relative',
  zIndex: 20,
  display: 'block',
  willChange: 'transform, opacity',
}

/*

*/
const TopPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "bg_top_image.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      topImage: file(relativePath: { eq: "portfolio/L1000101.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 800, placeholder: BLURRED)
        }
      }
    }
  `)
  const [isHovering, setIsHovering] = React.useState<Boolean>(false)
  const btnEnterPropeties: React.CSSProperties = {
    transition: isHovering ? 'transform 0.4s cubic-bezier(0.7,0,0.3,1)' : '',
  }
  const circleText: React.CSSProperties = {
    lineHeight: 1,
    overflow: 'hidden',
    gridArea: '1 / 1 / -1 / -1',
    transform: isHovering ? 'translateY(100%)' : '',
    transition: isHovering ? 'transform 0.3s ease-out' : '',
  }
  const circleProperties: React.CSSProperties = {
    width: '100%',
    height: '100%',
    gridArea: '1 / 1 / -1 / -1',
    fill: 'none',
    strokeWidth: '1px',
    stroke: 'white',
    zIndex: 40,
    color: 'white',
    transition: isHovering ? 'transform 0.3s ease-out' : '',
    transform: isHovering ? 'scale3d(1.4,1.4,1)' : '',
  }
  const handleMouseEnter = () => {
    setIsHovering(true)
  }
  const handleMouseLeave = () => {
    setIsHovering(false)
  }

  return (
    <React.Fragment>
      <Helmet>
        <body className={styles.body} />
      </Helmet>
      {/* Frame */}
      <div style={framePropeties}></div>
      {/* Content */}
      <div className={styles.content} style={contentPropeties}>
        <div style={contentItemProperties}>
          <GatsbyImage
            alt="background"
            image={getImage(data.backgroundImage)!}
            className={styles.content__bgImage}
            style={{ position: 'absolute' }} // GatsbyImageが relative で上書きしないように指定
          />
          <GatsbyImage
            alt="top"
            image={getImage(data.topImage)!}
            className={styles.content__topImage}
            style={{ position: 'absolute' }} // GatsbyImageが relative で上書きしないように指定
          />
          {/* <div className="w-full absolute h-full" style={{ top: 0, left: 0 }}> */}
          <h2 className={styles.content__displayName}>
            <span style={titleItemPropeties}>DAICHI</span>
            <span style={titleItemPropeties}>YAMAOKA</span>
          </h2>

          <Link
            to="/portfolio"
            className={styles.content__mainLink}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <button
              style={btnEnterPropeties}
              className={styles.content__mainLink__btn}
            >
              <span style={circleText}>Enter</span>
              <svg style={circleProperties} viewBox="0 0 100 100">
                <circle
                  vectorEffect="non-scaling-stroke"
                  cx="50"
                  cy="50"
                  r="48"
                ></circle>
              </svg>
            </button>
          </Link>
          {/* </div> */}
        </div>
      </div>
      {/* Grid */}
    </React.Fragment>
  )
}

const framePropeties: CSSProperties = {
  position: 'fixed',
  textAlign: 'center',
  zIndex: '1000',
  top: 0,
  left: 0,
  display: 'grid',
  alignContent: 'space-between',
  width: '100%',
  maxWidth: 'none',
  height: '100vh',
  padding: '1rem',
  pointerEvents: 'none',
  gridTemplateColumns: '50% 50%',
  gridTemplateRows: 'auto 1fr auto auto',
  gridTemplateAreas: "'... menu' '... ...' 'title ...' 'sponsor ...'",
}

const contentPropeties: CSSProperties = {
  display: 'grid',
  placeItems: 'center',
  width: '100%',
}

const contentItemProperties: CSSProperties = {
  height: '100vh',
  width: '100%',
  paddingTop: '1rem',
  display: 'grid',
  alignItems: 'center',
  position: 'absolute',
}

export default TopPage
