import * as React from 'react'
import Layout from '../components/layout'
import TopPage from '../components/layouts/TopPage/TopPage'

const IndexPage = () => {
  return (
    <Layout pageTitle="Home Page">
      <main>
        <TopPage />
      </main>
    </Layout>
  )
}

export default IndexPage
